import { ReactNode, useEffect } from "react";
import Sidebar from "./sidebar.component";
import Navbar from "./navbar.component";
import Footer from "./footer.component";
import LoadingSpinner from "../common/base-ui/loading/loading-spinner";
import { useContextAuthManager } from "../common/context/auth.context";
import { Subscription } from "rxjs";
import LineAPI from "../common/api/line.api";
import {
  setLineId,
  setRefreshToken,
  setToken,
} from "../common/service/local-storage";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../common/constant/routes";

type LayoutProps = {
  children: ReactNode;
  noNavbar?: boolean;
};

const Layout = ({ children, noNavbar = false }: LayoutProps) => {
  const navigate = useNavigate();
  const {
    isLoading,
    liff,
    isLoggedIn,
    isLogIn,
    setLineUserId,
    getUserData,
    logout,
  } = useContextAuthManager();

  useEffect(() => {
    window.scrollTo(0, 0);
    let subscribe = new Subscription();

    // check web login
    if (!isLogIn) {
      // check line login
      if (isLoggedIn) {
        (async () => {
          const profile = await liff.getProfile();
          if (profile) {
            // set to localStorage
            setLineId(profile.userId);
            // set to useState
            setLineUserId(profile.userId);
            const lineAPI = new LineAPI();
            lineAPI.lineLogin(profile.userId).subscribe({
              next: (data) => {
                setToken(data.token);
                setRefreshToken(data.refreshToken);
                subscribe = getUserData();
                return subscribe;
              },
              error: (err) => {
                console.error(err);
                if (err.code === 5000) {
                  return navigate(ROUTE.USER_CONNECT);
                }
              },
            });
          }
        })();
      }
    }

    return () => {
      subscribe.unsubscribe();
    };
  }, [liff, isLoggedIn, isLogIn, getUserData, navigate, setLineUserId, logout]);

  return (
    <div className="h-full">
      <Sidebar />
      <Navbar noNavbar={noNavbar} />
      <div className="lg:ml-[220px] min-h-[calc(100vh-54px)]">
        <div className="container flex flex-col items-center pt-6 pb-[70px] lg:pb-0 relative">
          {children}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
      {isLogIn && <Footer logout={logout} />}
    </div>
  );
};
export default Layout;
