import { Liff } from "@line/liff/dist/lib";
import { createContext, ReactNode, useContext } from "react";
import { Subscription } from "rxjs";
import { useAuth } from "./auth.model";

type IAuthManager = {
  children: ReactNode;
};

type ContextType = {
  setIsLogIn: (isLogIn: boolean) => void;
  isLogIn: boolean;
  logout: () => void;
  favoriteCount: number;
  setFavoriteCount: (count: number) => void;
  recentPlayCount: number;
  setRecentPlayCount: (count: number) => void;
  balance: number;
  username: string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  lineLogin: () => void;
  affId: string;
  introduceId: string;
  lineUserId: string;
  isLoggedIn: boolean;
  liff: Liff;
  setLineUserId: (lineUserId: string) => void;
  getUserData: () => Subscription;
};

export const AuthManagerContext = createContext({} as ContextType);

const AuthManager = ({ children }: IAuthManager) => {
  const {
    isLogIn,
    setIsLogIn,
    logout,
    favoriteCount,
    setFavoriteCount,
    recentPlayCount,
    setRecentPlayCount,
    balance,
    username,
    isLoading,
    lineLogin,
    affId,
    introduceId,
    lineUserId,
    setIsLoading,
    isLoggedIn,
    liff,
    setLineUserId,
    getUserData,
  } = useAuth();
  return (
    <AuthManagerContext.Provider
      value={{
        setIsLogIn,
        isLogIn,
        logout,
        favoriteCount,
        setFavoriteCount,
        recentPlayCount,
        setRecentPlayCount,
        balance,
        username,
        isLoading,
        lineLogin,
        affId,
        introduceId,
        lineUserId,
        setIsLoading,
        isLoggedIn,
        liff,
        setLineUserId,
        getUserData,
      }}
    >
      {children}
    </AuthManagerContext.Provider>
  );
};

export const useContextAuthManager = () => {
  return useContext(AuthManagerContext);
};

export default AuthManager;
