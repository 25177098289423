export const GROUP_ID = `${process.env.REACT_APP_GROUP_ID}`;
export const API_URL = `${process.env.REACT_APP_API}`;
export const XBET_API = `${process.env.REACT_APP_XBET_API}`;
export const APP_NAME = `${process.env.REACT_APP_NAME}`;
export const REDEMPTION_API = `${process.env.REACT_APP_REDEMPTION_API}`;
export const LINE_LIFF_ID = `${
  process.env.REACT_APP_LINE_LIFF_ID || "1656873332-yLDRRk6n"
}`;
export const LINE_OFFICIAL_LINK = `${process.env.REACT_APP_LINE_OFFICIAL_LINK}`;
export const CMS_API = `${process.env.REACT_APP_CMS_API}`;
