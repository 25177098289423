import DepositCard from "./component/deposit-card.component"
import Description from "./component/description.component"
import TrueWallet from "./component/true-wallet.component"
import { useEffect, useMemo, useState } from "react"
import UserAPI from "../../common/api/user.api"
import { isIOS } from "react-device-detect"
import { IBankAccount, IPromptPay } from "../../common/types/topup.type"
import { ICON_NAME } from "../../common/base-ui/icon/icon.component"
import toast from "react-hot-toast"
import Title from "../../common/base-ui/title.component"
import { useNavigate } from "react-router-dom"
import { APP_NAME } from "../../common/service/env"
import AlertModal from "../../common/base-ui/modal/alert-modal.component"
import { ErrorModal } from "../../common/types/modal.type"
import useLogo from "../../common/hooks/useLogo.hooks"
import PromptPayCard from "./component/prompt-pay-card.component"

const Deposit = () => {
  const navigate = useNavigate()
  const userRepository = useMemo(() => {
    return new UserAPI()
  }, [])
  const [trueWalletStatus, setTrueWalletUrlStatus] = useState(false)
  const [depositBank, setDepositBank] = useState<IBankAccount>()
  const [promptPay, setPromptPay] = useState<IPromptPay[]>([])
  const [userBank, setUserBank] = useState<IBankAccount>()

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal)
  const { mobileLogo } = useLogo()

  useEffect(() => {
    userRepository.bankAccount().subscribe({
      next: (res) => {
        setDepositBank(res.depositBank)
        setPromptPay(res.promptPay)
        setUserBank(res.userBank)
      },
      error: (err) => {
        console.error(err)
        if (err.code === 401) {
          setErrorMessage({
            message: "Session ของคุณหมดอายุ กรุณาล็อคอินใหม่อีกครั้ง",
            code: 401,
            gotoLogin: true,
          })
          setIsAlertModalOpen(true)
        }
      },
    })
  }, [userRepository, navigate])

  useEffect(() => {
    userRepository.truewalletStatus().subscribe({
      next: (status) => {
        setTrueWalletUrlStatus(status)
      },
      error: (err) => {
        console.error(err)
      },
    })
  }, [userRepository])

  const getTrueWalletUrl = () => {
    userRepository.truewalletUrl().subscribe({
      next: (url) => {
        if (url) {
          const trueWalletUrl = url + "&redirect=" + APP_NAME
          if (isIOS) {
            window.location.href = trueWalletUrl
            return
          }
          return window.open(trueWalletUrl, "_blank")
        }
      },
      error: (err) => {
        console.error(err)
      },
    })
  }

  const handleClickPromptPay = (id: string) => {
    const toastId = toast.loading("กำลังพาไปยังหน้าฝากเงินด้วย PromptPay...")
    userRepository.promptPay(id).subscribe({
      next: (res) => {
        if (res) {
          toast.dismiss(toastId)
          // if (isIOS) {
          //   window.location.href = res
          //   return
          // }
          return window.open(res)
        }
      },
      error: (err) => {
        toast.dismiss(toastId)
        console.error(err)
        setErrorMessage({
          message: err.message,
          code: err.code,
        })
      },
    })
  }

  const copyToClipboard = (bankNumber: string) => {
    navigator.clipboard.writeText(bankNumber)
    toast.success("คัดลอกลงคลิปบอร์ด!")
  }

  return (
    <>
      <Title titleEn="Deposit" titleTh="ทำรายการฝากเงิน" hasTab />
      <PromptPayCard promptPay={promptPay} handleClickPromptPay={handleClickPromptPay} />
      <DepositCard
        webLogo={mobileLogo}
        bankLogo={depositBank?.bank.bankTag as ICON_NAME}
        bankName={depositBank?.bank.bankName}
        bankAccountNumber={depositBank?.number}
        bankAccountName={depositBank?.name}
        copyToClipboard={copyToClipboard}
      />
      <Description customerBankName={userBank?.bank.bankName} customerBankAccountNumber={userBank?.number} />
      {trueWalletStatus && <TrueWallet getTrueWalletUrl={getTrueWalletUrl} />}
      <AlertModal isOpen={isAlertModalOpen} setIsOpen={setIsAlertModalOpen} error={errorMessage} />
    </>
  )
}
export default Deposit
